import React from 'react';
import { Hero as HeroBase } from '@vcc-package/promotions';
import SectionContained from '@vcc-www/page-section/DEPRECATED_BROKEN_SectionContained';
import SectionCover from '@vcc-www/page-section/SectionCover';
import HeroProps from './Hero.props';

const Hero: React.FC<HeroProps> = ({
  content,
  renderFullWidth,
  topInnerSpacing = 'none',
  ...params
}) => {
  const { disclaimer } = content;

  const Section = renderFullWidth ? SectionCover : SectionContained;

  return (
    <Section {...params} topInnerSpacing={topInnerSpacing} data-autoid="hero">
      <HeroBase
        video={{
          landscapePosterSrc: content.landscapeImagePlaceholder?.src,
          portraitPosterSrc: content.portraitImagePlaceholder?.src,
          landscapeMp4Src: content.landscapeVideoMp4?.src,
          portraitMp4Src: content.portraitVideoMp4?.src,
          landscapeWebmSrc: content.landscapeVideoWebm?.src,
          portraitWebmSrc: content.portraitVideoWebm?.src,
          videoDescription: content.videoDescription,
          enableAudio: content.enableAudio,
          disableAutoPlay: content.disableAutoPlay,
        }}
        image={{
          landscapeSrc: content.landscapeImageFallback?.src,
          portraitSrc: content.portraitImageFallback?.src,
          altText:
            content.portraitImageFallback?.alt ||
            content.landscapeImageFallback?.alt,
        }}
        disclaimerText={disclaimer}
      />
    </Section>
  );
};

export default Hero;
