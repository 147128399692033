import React, { FC, useEffect, useRef, useState } from 'react';
import { ExtendCSS, Text } from 'vcc-ui';

const titleAnimationScale = 0.8;

interface Props {
  text?: string | undefined;
  title?: string | undefined;
  smallText?: string;
  desktopTextPosition: string | undefined;
  smallTitle: boolean;
  headingTag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
}

const Component: FC<React.PropsWithChildren<Props>> = ({
  text,
  title,
  smallText,
  desktopTextPosition,
  smallTitle,
  headingTag,
}) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const current: Element = ref.current;
      setTitleHeight(current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <>
      {text && (
        <Text
          variant={['columbus', 'columbus', 'columbus', 'columbus']}
          subStyle="emphasis"
          extend={textCSS(
            titleHeight,
            smallTitle,
            innerTextPositionCSS(desktopTextPosition),
          )}
        >
          {text}
        </Text>
      )}
      {title && (
        <Text
          variant={['cook', 'cook', 'cook', 'cook']}
          subStyle="emphasis"
          extend={titleCSS(
            smallTitle,
            !!smallText,
            innerTextPositionCSS(desktopTextPosition),
          )}
          ref={ref}
          as={headingTag}
        >
          {title}
        </Text>
      )}
      {smallText && (
        <Text
          variant={['columbus', 'columbus', 'columbus', 'columbus']}
          subStyle="standard"
          extend={smallTextCSS(innerTextPositionCSS(desktopTextPosition))}
          ref={ref}
        >
          {smallText}
        </Text>
      )}
    </>
  );
};

const textCSS =
  (
    titleHeight: number,
    smallTitle: boolean,
    innerTextPositionCSS: ExtendCSS,
  ): ExtendCSS =>
  ({ theme: { color, baselineGrid } }) => {
    return {
      color: color.foreground.primary,
      textAlign: 'center',
      whiteSpace: 'pre-line',
      marginBottom: baselineGrid * 1,
      lineHeight: '1 !important',
      fromL: {
        ...innerTextPositionCSS,
        marginBottom: baselineGrid * 2,
        transition: 'transform 600ms cubic-bezier(0.65, 0, 0.35, 1)',
        transform: smallTitle
          ? `translate(0px, ${titleHeight * (1 - titleAnimationScale)}px)`
          : `translate(0px, 0px)`,
      },
    };
  };

const titleCSS =
  (
    smallTitle: boolean,
    smallText: boolean,
    innerTextPositionCSS: ExtendCSS,
  ): ExtendCSS =>
  ({ theme: { color, baselineGrid } }) => ({
    color: color.foreground.primary,
    textAlign: 'center',
    marginBottom: smallText ? baselineGrid * 1 : baselineGrid * 3,
    lineHeight: '1 !important',
    fromL: {
      ...innerTextPositionCSS,
      marginBottom: smallText ? baselineGrid * 2 : baselineGrid * 3,
      transition: 'transform 600ms cubic-bezier(0.65, 0, 0.35, 1)',
      transform: smallTitle
        ? `scaleX(${titleAnimationScale}) scaleY(${titleAnimationScale})`
        : 'scaleX(1) scaleY(1)',
    },
  });

const smallTextCSS =
  (innerTextPositionCSS: ExtendCSS): ExtendCSS =>
  ({ theme: { color, baselineGrid } }) => ({
    color: color.foreground.primary,
    textAlign: 'center',
    marginBottom: baselineGrid * 3,
    fromL: {
      ...innerTextPositionCSS,
      marginBottom: baselineGrid * 3,
    },
  });

const innerTextPositionCSS = (
  desktopTextPosition: string | undefined,
): ExtendCSS => {
  switch (desktopTextPosition) {
    case 'middleLeft':
      return {
        transformOrigin: 'bottom left',
        textAlign: 'left',
        maxWidth: '500px',
      };
    case 'bottomRight':
      return {
        transformOrigin: 'bottom right',
        textAlign: 'right',
        maxWidth: '500px',
      };
    case 'middleRight':
      return {
        transformOrigin: 'bottom right',
        textAlign: 'right',
        maxWidth: '500px',
      };
    case 'center':
      return {
        textAlign: 'center',
        transformOrigin: 'bottom center',
        maxWidth: '500px',
      };
    default:
      return {
        textAlign: 'left',
        transformOrigin: 'bottom left',
        maxWidth: '500px',
      };
  }
};

export default Component;
