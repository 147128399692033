'use client';

import React from 'react';
import { Image_DEPRECATED } from '@vcc-package/media/DEPRECATED';
import Video from '@vcc-www/video';
import Buttons from './components/Buttons';
import { Block, ExtendCSS, Flex, ThemePicker, useTheme } from 'vcc-ui';
import Disclaimer from '@vcc-www/disclaimer';
import { useBreakpoints } from '@vcc-www/hooks';
import Texts from './components/Texts';
import { HeroType } from './type';

export const Hero: React.FC<React.PropsWithChildren<HeroType>> = ({
  video,
  image,
  disclaimerText,
  aspectRatioProp,
  fetchPriority,
  text,
  buttons,
  miscellaneousProps = {
    themeColor: 'dark',
    lowerButtonsOnMobile: false,
    lowerButtonsOnTablet: false,
    desktopTextPosition: 'bottomLeft',
  },
  sizes,
}: HeroType) => {
  const hasInfo = text || buttons;
  const hasVideo = !!(video.landscapeMp4Src && video.portraitMp4Src);
  const hasImage = !!(image.landscapeSrc && image.portraitSrc);
  const videoPoster = video.tabletPosterSrc
    ? {
        default: video.portraitPosterSrc || '',
        fromM: video.tabletPosterSrc || '',
        fromL: video.landscapePosterSrc || '',
      }
    : {
        default: video.landscapePosterSrc || '',
        untilL: video.portraitPosterSrc,
      };
  const srcMp4 = video.tabletMp4Src
    ? {
        default: video.portraitMp4Src || '',
        fromM: video.tabletMp4Src || '',
        fromL: video.landscapeMp4Src || '',
      }
    : {
        default: video.landscapeMp4Src || '',
        untilL: video.portraitMp4Src,
      };
  const srcWebM = video.tabletWebmSrc
    ? {
        default: video.portraitWebmSrc || '',
        fromM: video.tabletWebmSrc || '',
        fromL: video.landscapeWebmSrc || '',
      }
    : {
        default: video.landscapeWebmSrc || '',
        untilL: video.portraitWebmSrc,
      };
  const imgSources = image.tabletSrc
    ? {
        default: image.portraitSrc || '',
        fromM: image.tabletSrc || '',
        fromL: image.landscapeSrc || '',
      }
    : {
        default: image.landscapeSrc || '',
        untilL: image.portraitSrc || '',
      };
  const aspectRatio = aspectRatioProp
    ? aspectRatioProp
    : {
        default: [21, 9],
        untilL: [1, 1],
      };
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { onlyS, onlyM } = useBreakpoints();
  const { baselineGrid } = useTheme();
  const showButtonsLower =
    (onlyS && miscellaneousProps.lowerButtonsOnMobile) ||
    (onlyM && miscellaneousProps.lowerButtonsOnTablet);

  return (
    <>
      <Block extend={containerCSS}>
        {hasVideo ? (
          <Video
            poster={videoPoster}
            srcMp4={srcMp4}
            srcWebM={srcWebM}
            data-autoid="hero:video"
            data-testid="hero:video"
            aspectRatio={aspectRatio}
            loop
            shouldAutoplay={!video.disableAutoPlay}
            controls={video.disableControls}
            muted={!video.enableAudio}
            videoDescription={video.videoDescription}
            fetchPriority={fetchPriority}
          />
        ) : (
          hasImage && (
            <Image_DEPRECATED
              loading="eager"
              sources={imgSources}
              aspectRatio={aspectRatio}
              objectFit="cover"
              alt={image.altText || ''}
              data-autoid="hero:image"
              data-testid="hero:image"
              fetchPriority={fetchPriority}
              imageDensity={image.imageDensity}
              sizes={sizes || '100vw'}
            />
          )
        )}
        {hasInfo && (
          <>
            <Block
              extend={gradientBlockCSS(miscellaneousProps.desktopTextPosition)}
            />
            <ThemePicker variant="dark">
              <Flex
                extend={contentWrapperCSS(
                  miscellaneousProps.desktopTextPosition,
                )}
              >
                <Flex
                  extend={textsWrapperCSS(
                    miscellaneousProps.desktopTextPosition,
                    baselineGrid,
                  )}
                >
                  <Texts
                    text={text?.text}
                    title={text?.title}
                    smallText={text?.smallText}
                    desktopTextPosition={miscellaneousProps.desktopTextPosition}
                    smallTitle={false}
                    headingTag={text?.headingTag || 'p'}
                  />
                  {!showButtonsLower && (buttons?.cta1 || buttons?.cta2) && (
                    <Buttons
                      cta1={{
                        href: buttons?.cta1?.href,
                        text: buttons?.cta1?.text,
                        target: buttons?.cta1?.target,
                      }}
                      cta2={{
                        href: buttons?.cta2?.href,
                        text: buttons?.cta2?.text,
                        target: buttons?.cta2?.target,
                      }}
                      intent={
                        miscellaneousProps.themeColor === 'light'
                          ? undefined
                          : 'secondary'
                      }
                      withTexts
                      desktopTextPosition={
                        miscellaneousProps.desktopTextPosition
                      }
                    />
                  )}
                </Flex>
              </Flex>
            </ThemePicker>

            {showButtonsLower && (buttons?.cta1 || buttons?.cta2) && (
              <Buttons
                cta1={{
                  href: buttons?.cta1?.href,
                  text: buttons?.cta1?.text,
                  target: buttons?.cta1?.target,
                }}
                cta2={{
                  href: buttons?.cta2?.href,
                  text: buttons?.cta2?.text,
                  target: buttons?.cta2?.target,
                }}
                intent={undefined}
                withTexts={false}
              />
            )}
          </>
        )}
      </Block>
      {!!disclaimerText && (
        <Flex extend={disclaimerFlexCSS}>
          <Disclaimer text={disclaimerText} extend={disclaimerBlockCSS} />
        </Flex>
      )}
    </>
  );
};

const gradientSettings =
  'rgba(14, 14, 14, 0), rgba(14, 14, 14, 0.15), rgba(14, 14, 14, 0.3)';
const containerCSS: ExtendCSS = () => ({
  position: 'relative',
  margin: 0,
});
const gradientBlockCSS = (
  desktopTextPosition: string | undefined,
): ExtendCSS => {
  let backgroundCSS: ExtendCSS;
  switch (desktopTextPosition) {
    case 'middleLeft':
      backgroundCSS = {
        background: `radial-gradient(circle at 80% 50%, ${gradientSettings})`,
      };
      break;
    case 'bottomRight':
      backgroundCSS = {
        background: `radial-gradient(circle at 20% 50%, ${gradientSettings})`,
      };
      break;
    case 'middleRight':
      backgroundCSS = {
        background: `radial-gradient(circle at 20% 50%, ${gradientSettings})`,
      };
      break;
    case 'center':
      backgroundCSS = {
        background: `radial-gradient(circle at 50% 60%, ${gradientSettings})`,
      };
      break;
    default:
      backgroundCSS = {
        background: `radial-gradient(circle at 80% 50%, ${gradientSettings})`,
      };
  }
  return {
    position: 'absolute',
    maxWidth: '100%',
    overflow: 'hidden',
    height: '100%',
    left: 0,
    top: 0,
    right: 0,
    pointerEvents: 'none',
    onlyS: {
      background: `radial-gradient(circle at 50% 65%, ${gradientSettings})`,
    },
    onlyM: {
      background: `radial-gradient(circle at 50% 60%, ${gradientSettings})`,
    },
    fromL: {
      ...backgroundCSS,
    },
    fromXL: {
      ...(desktopTextPosition === 'center' && {
        background: `radial-gradient(circle at 50% 50%, ${gradientSettings})`,
      }),
    },
  };
};

const contentWrapperCSS = (
  desktopTextPosition: string | undefined,
): ExtendCSS => {
  let desktopCSS: ExtendCSS;
  switch (desktopTextPosition) {
    case 'middleLeft':
      desktopCSS = { flexDirection: 'row' };
      break;
    case 'bottomRight':
      desktopCSS = { flexDirection: 'row-reverse' };
      break;
    case 'middleRight':
      desktopCSS = { flexDirection: 'row-reverse' };
      break;
    case 'center':
      desktopCSS = { alignItems: 'center' };
      break;
    default:
      desktopCSS = { flexDirection: 'row' };
  }
  return {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    height: '100%',
    pointerEvents: 'none',
    fromL: {
      ...desktopCSS,
    },
  };
};

const textsWrapperCSS = (
  desktopTextPosition: string | undefined,
  baselineGrid: number,
): ExtendCSS => {
  let desktopCSS: ExtendCSS;
  switch (desktopTextPosition) {
    case 'middleLeft':
      desktopCSS = {
        justifyContent: 'center',
        paddingBottom: 0,
        contentDirection: 'row',
        marginTop: 0,
        marginLeft: baselineGrid * 10,
        marginRight: 0,
      };
      break;
    case 'bottomRight':
      desktopCSS = {
        justifyContent: 'flex-end',
        paddingBottom: baselineGrid * 10,
        contentDirection: 'row',
        marginTop: 0,
        marginLeft: 0,
        marginRight: baselineGrid * 10,
      };
      break;
    case 'middleRight':
      desktopCSS = {
        justifyContent: 'center',
        paddingBottom: 0,
        contentDirection: 'row',
        marginTop: 0,
        marginLeft: 0,
        marginRight: baselineGrid * 10,
      };
      break;
    case 'center':
      desktopCSS = {
        paddingBottom: 0,
        marginTop: baselineGrid * 8,
        marginLeft: 0,
        marginRight: 0,
        textAlign: 'center',
        alignItems: 'center',
      };
      break;
    default:
      desktopCSS = {
        justifyContent: 'flex-end',
        paddingBottom: baselineGrid * 10,
        contentDirection: 'row',
        marginTop: 0,
        marginLeft: baselineGrid * 10,
        marginRight: 0,
      };
  }

  return ({ theme: { baselineGrid } }) => ({
    marginTop: baselineGrid * 4,
    marginRight: baselineGrid * 3,
    marginLeft: baselineGrid * 3,
    maxWidth: '100%',
    overflow: 'hidden',
    pointerEvents: 'none',
    left: 0,
    top: 0,
    right: 0,
    fromM: {
      marginTop: baselineGrid * 6,
    },
    fromL: {
      ...desktopCSS,
      height: '100%',
    },
  });
};

const disclaimerFlexCSS: ExtendCSS = {
  flexDirection: 'row',
  justifyContent: 'center',
};

const disclaimerBlockCSS: ExtendCSS = ({ theme: { baselineGrid } }) => ({
  margin: `${baselineGrid * 3}px ${baselineGrid * 3}px 0px ${
    baselineGrid * 3
  }px`,
  width: '100%',
  fromL: {
    margin: `${baselineGrid * 3}px 0px 0px 0px`,
  },
});
